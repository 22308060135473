import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LayoutGalerieListeImages from "../../composants/layout/fr/LayoutGalerieListeImages"

import { obtenirImages, obtenirPage } from "../../js/client-es/utils"
import fragmentsImages from "../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageYamlGalerieZooms: allPagesCompilationYaml(
      filter: { code: { eq: "galerieZoomsEt360Degres" } }
    ) {
      ...FragmentPageYaml
    }
    imagesPaysage: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "hessII360"
            "teide360"
            "godafoss360"
            "yeuxDuSalar360"
            "observatoireRoqueMuchachos360"
            "damaraland360"
            "voieLacteeIntegralite360"
            "orionZoom"
            "winterSkiesZoom"
          ]
        }
      }
    ) {
      ...FragmentImagePaysage
    }
    imagesPortrait: allImagesCompilationYaml(
      filter: { code: { in: ["megalaxyZoomZoom", "tresorsCygneZoom"] } }
    ) {
      ...FragmentImagePortrait
    }
  }
`

export default function IndexGalerieVoieLactee() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageYamlGalerieZooms")
  const images = obtenirImages(
    resultatsRequete,
    "imagesPaysage",
    "imagesPortrait"
  )

  return <LayoutGalerieListeImages page={page} images={images} />
}
